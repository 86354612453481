.TalentAgencySelect-header {
    box-sizing: border-box;
    width: 100%;
    padding: 40px 16px 80px 16px;
    margin-bottom: -40px;
    text-align: center;
    background-color: #aaaaaa;
}

.TalentAgencySelect-card {
    width: 100%;
    max-width: 600px;
    padding: 16px 16px 24px 16px;
    margin: 0 auto 24px auto;
}